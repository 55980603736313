import React from 'react';
import Layout from '../components/layout';
import MyNavbar from '../components/navbar/navbar';
import Footer from '../components/footer';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Breadcrumb from "../components/breadcrumb";
import Sidebar from '../components/sidebar';
import Cta from "../components/cta";

import main_img from "../images/tillverkning/tillverkning_4.jpg";
import main_2_img from "../images/tillverkning/pall.jpg";
import img_1 from '../images/tillverkning/tillverkning_1.jpg';
import img_2 from '../images/tillverkning/tillverkning_2.jpg';
import img_3 from '../images/tillverkning/tillverkning_3.jpg';
import img_4 from '../images/tillverkning/tillverkning_5.jpg';
import favicon from '../images/favicon.ico';

function Tillverkning() {
    return (
        <Layout>
            <Helmet>
                <title>Tillverkning - Danas Plåt &amp; Smide AB</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Vi tillverkar allt med plåt och smide från tunnplåt till järn" />
                <meta name="keywords" content="Tillverkning, Svetsarbeten, tunnplåt, järn" />
                <meta property="og:title" content="Tillverkning - Danas Plåt och Smide AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://danasplat.se/tillverkning" />
                <link rel="cannonical" href="https://danasplat.se/tillverkning" />
            </Helmet>

            <MyNavbar active_page="" />
            <section>
                <div className="h-60 md:h-96 w-screen bg-tillverkning bg-center bg-no-repeat bg-cover">
                    <div className="h-60 md:h-96 w-screen  bg-opacity-50 bg-black flex justify-center items-center ">
                        <h1 className="text-4xl sm:text-5xl md:text-7xl font-extrabold text-center text-white">Tillverkning</h1>
                    </div>
                </div>
                <Breadcrumb page="Tillverkning"/>
            </section>

            <section>
                <div className="container mx-auto px-4 pt-24">
                    <div className="grid lg:grid-cols-2 gap-24 lg:gap-10">
                        <div className="bg-white">
                            <div className="w-full h-full flex flex-col justify-center">
                                <h2 className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-left text-green-900">Vi tillverkar allt med plåt och smide</h2>
                                <p className="lg:text-xl text-gray-600 mt-12">Vi tillverkar allt med plåt &amp; smide från tunnplåt till järn. Vi har sedan 1966 erbjudit våra kunder bra leveranstider, personlig service och ett brett kunnande inom branschen. Vi är specialister inom dörrar, fönster och fasader, men levererar även trappor, räcken, byggnadssmide med mycket mer. Allt efter era idéer och önskemål.</p>
                                <p className="lg:text-xl text-gray-600 mt-6">Tillverkningen av färdiga konstruktioner med montage hos uppdragsgivaren utföres enligt beställarens konstruktionsritningar eller idé.</p>
                                <p className="lg:text-xl text-gray-600 mt-6">Våra medarbetare är välutbildade och har den kompetens, de licenser, prövningar och certifieringar som krävs för att utföra de avancerade svetsuppdrag vi jobbar med.</p>

                                <Link to="/kontakt" className="block mt-12">
                                    <span className="bg-red-700 shadow-xl text-white text-md md:text-xl py-4 px-6 rounded hover:bg-red-800">Kontakta oss</span>
                                </Link>
                            </div>
                        </div>

                        <div className="w-full h-full">
                            <div className="w-full h-full bg-image-square bg-no-repeat bg-custome bg-right-bottom">
                                <div className="w-full h-full bg-image-circel bg-no-repeat bg-custome bg-left-top p-4 lg:p-8">
                                    <img alt="Tillverkning av smide " className="w-full rounded-tl-3xl rounded-br-3xl rounded-tr-md rounded-bl-md  shadow-xl object-cover h-full object-center block" src={main_img} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mx-auto px-4 py-24">
                    <hr/>
                </div>
            </section>

            <section>
                <div className="container mx-auto px-4">
                    <div className="grid lg:grid-cols-2 gap-24 lg:gap-10">
                        <div className="w-full h-full">
                            <div className="w-full h-full bg-image-square bg-no-repeat bg-custome bg-right-bottom">
                                <div className="w-full h-full bg-image-circel bg-no-repeat bg-custome bg-left-top p-4 lg:p-8">
                                    <img alt="tillverka pall" className="w-full rounded-tl-3xl rounded-br-3xl rounded-tr-md rounded-bl-md  shadow-xl object-cover h-full object-center block" src={main_2_img} />
                                </div>
                            </div>
                        </div>

                        <div className="bg-white">
                            <div className="w-full h-full flex flex-col justify-center">
                                <h2 className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-left text-green-900">Tillverkning och Pallservice</h2>
                                <p className="lg:text-xl text-gray-600 mt-12">Vi på Danas Plåt och Smide AB har en modern verkstad och erbjuder även pallservice både för standardpall och kundanpassad specialpall.</p>
                                <p className="lg:text-xl text-gray-600 mt-6">Tryck på knappen nedan för mer information eller kom i kontakt med oss så berättar vi gärna mer.</p>
                                <Link to="/pallservice" className="block mt-12">
                                    <span className="shadow-xl border border-red-700 text-red-700 text-md md:text-xl py-3 px-6 rounded hover:bg-red-800 hover:text-white">Läs mer</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mx-auto px-4 pt-24 pb-32">
                    <hr/>
                </div>
            </section>

            <section>
                <div className="container mx-auto px-4 pb-32">
                    <div className="grid grid-cols-12 gap-10">
                        <div className="col-span-full lg:col-span-3">
                            <Sidebar />
                        </div>
                        <div className="col-span-full lg:col-span-9">
                            <div className="w-full grid lg:grid-cols-2 gap-10">
                                <img alt="Smide tillverkning i Södertälje" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_1} />
                                <img alt="tillverkning och svetsarbete" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_2} />
                                <img alt="tillverkning specialister" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_3} />
                                <img alt="experter på tillverkning och montage" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_4} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Cta />
            <Footer />
        </Layout>
    );
}
export default Tillverkning;